import React, { useState } from 'react';
import { MenuItem } from '../../../types/menu.interface';
import Link from 'next/link';
import styles from './nav-item.module.scss';
import { isMobile } from 'react-device-detect';
import Submenu from '../submenu/submenu.component';

type Props = {
  item: MenuItem;
  id: string;
  dark: boolean;
};

const NavItem = ({ item, id, dark }: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <li
      className={dark ? styles.navItemDark : styles.navItem}
      onMouseEnter={() => (isMobile ? null : setVisible(true))}
      onMouseLeave={() => setVisible(false)}
    >
      {item.children.length ? (
        <>
          <a href="#" onClick={() => setVisible(!visible)}>
            {item.name}
          </a>
          {visible && (
            <ul
              className={styles.submenu}
              style={{
                height:
                  window.innerWidth < 1200
                    ? `${item.children.length * 60}px`
                    : 'auto',
              }}
            >
              {item.children.map((child, j) => (
                <li key={`${id}-${j}`} className={styles.subitem}>
                  {child.children?.length ? (
                    <Submenu item={child} id={j.toString()} dark={dark} />
                  ) : (
                    <Link href={child.url || ''}>
                      <a>{child.name}</a>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <Link href={item.url || ''}>
          <a>{item.name}</a>
        </Link>
      )}
    </li>
  );
};

export default NavItem;
