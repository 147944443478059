import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Menu } from '../../../types/menu.interface';
import Branding from '../branding/branding.component';
import NavItem from '../nav-item/nav-item.component';

import styles from './nav.module.scss';

type Props = {
  menu: Menu;
  branding: string;
  isInitiallyDark?: boolean;
  baseUrl: string;
};

const Nav = ({ menu, branding, baseUrl, isInitiallyDark = true }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [dark, setDark] = useState(isInitiallyDark);

  useEffect(() => {
    const handler = () => {
      const header = document.querySelector('#start');
      if (header) {
        setDark(window.scrollY > header?.getBoundingClientRect().height);
      }
    };
    window.addEventListener('scroll', handler);
    handler();
    () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <nav className={dark ? styles.navDark : styles.nav}>
      <div className={styles.navContainer}>
        <Branding imageUrl={branding} baseUrl={baseUrl} visible={dark} />
        {menu.content.length ? (
          <ul
            className={`${styles.menuWrapper} ${
              menuVisible ? styles.menuVisible : ''
            }`}
          >
            <li>
              <a
                href="#"
                className={styles.hamburger}
                onClick={() => setMenuVisible(!menuVisible)}
              >
                <FontAwesomeIcon icon={menuVisible ? faTimes : faBars} />
              </a>
            </li>
            {menu.content.map((item, i) => (
              <NavItem
                key={`${menu.id}-${i}`}
                item={item}
                id={`${menu.id}-${i}`}
                dark={dark}
              />
            ))}
          </ul>
        ) : (
          ''
        )}
      </div>
    </nav>
  );
};

export default Nav;
