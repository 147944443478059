import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from './branding.module.scss';

type Props = {
  imageUrl: string;
  baseUrl: string;
  visible: boolean;
};

const Branding = (props: Props) => {
  return (
    <div
      className={
        props.visible ? styles.brandingWrapper : styles.brandingWrapperDark
      }
    >
      <Link href="/">
        <a aria-hidden="true" tabIndex={-1}>
          {props.imageUrl ? (
            <Image
              loading="eager"
              src={`http://localhost:5000${props.imageUrl}`}
              alt="branding"
              height={70}
              width={162}
            />
          ) : (
            <h1>BERENIKE</h1>
          )}
        </a>
      </Link>
    </div>
  );
};

export default Branding;
