import Link from 'next/link';
import React, { MouseEventHandler, ReactNode } from 'react';
import styles from './button.module.scss';

type Props = {
  url?: string;
  children: ReactNode;
  btn?: boolean;
  small?: boolean;
  css?: { [key: string]: string };
  type?: 'button' | 'submit';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
};
const Button = ({
  url,
  children,
  btn,
  small,
  css,
  type = 'button',
  onClick,
  ariaLabel,
}: Props) => {
  return btn ? (
    <button
      aria-label={ariaLabel}
      style={css}
      className={small ? styles.buttonSmall : styles.button}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  ) : (
    <Link href={url || ''}>
      <a style={css} className={small ? styles.buttonSmall : styles.button}>
        {children}
      </a>
    </Link>
  );
};

export default Button;
