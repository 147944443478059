import React, { ReactNode } from 'react';
import styles from './container.module.scss';

type Props = {
  children: ReactNode;
  first?: boolean;
  column?: boolean;
  css?: { [key: string]: string };
};

const Container = ({ children, first, column, css = {} }: Props) => {
  return (
    <div
      className={styles.container}
      style={{
        height: first ? '100vh' : 'auto',
        paddingTop: first ? '0' : '125px',
        flexDirection: column ? 'column' : 'row',
        justifyContent: first ? 'flex-end' : 'initial',
        ...css,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
