import React from 'react';
import { MappedOptions } from '../../types/mapped-options-and-menus.interface';
import Container from '../container/container.component';
import CookieConsent from 'react-cookie-consent';
import Image from 'next/image';

type Props = {
  options: MappedOptions;
};

const Footer = (props: Props) => {
  return (
    <footer>
      <Container
        css={{
          height: '80px',
          padding: '0',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p>
          &copy; {new Date().getFullYear()} {props.options.authors}
        </p>
        {props.options.footerInstitution1 &&
          props.options.footerInstitution2 && (
            <div
              style={{
                height: '80px',
                padding: '0',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <p>Research Institutions:</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={props.options.footerInstitution1Url}
              >
                <Image
                  src={`http://localhost:5000${props.options.footerInstitution1}`}
                  height={70}
                  width={296}
                  alt="sponsor1"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={props.options.footerInstitution2Url}
              >
                <Image
                  src={`http://localhost:5000${props.options.footerInstitution2}`}
                  height={70}
                  width={166}
                  alt="sponsor2"
                />
              </a>
            </div>
          )}
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="marmora-gdpr-google-analytics"
      >
        {props.options.cookieConsent}
      </CookieConsent>
    </footer>
  );
};

export default Footer;
