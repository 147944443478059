import React, { useState } from 'react';
import { MenuItem } from '../../../types/menu.interface';
import styles from './submenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

type Props = {
  item: MenuItem;
  id: string;
  dark: boolean;
};

const Submenu: React.FC<Props> = ({ item, id, dark }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => (isMobile ? null : setVisible(true))}
      onMouseLeave={() => setVisible(false)}
    >
      <a href="#" onClick={() => setVisible(!visible)}>
        {item.name}
      </a>{' '}
      <FontAwesomeIcon icon={faCaretRight} />
      {visible && (
        <ul
          className={styles.submenu}
          style={{
            height: isMobile ? `${item.children.length * 50}px` : 'auto',
          }}
        >
          {item.children.map((child, j) => (
            <li
              key={`${id}-${j}`}
              className={dark ? styles.subitemLinkDark : styles.subitemLink}
            >
              <Link href={child.url || ''}>
                <a>{child.name}</a>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Submenu;
