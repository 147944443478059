import React, { ReactChild, ReactNode } from 'react';
import Footer from '../components/footer/footer.component';
import Nav from '../components/navbar/nav/nav.component';
import { Menu } from '../types/menu.interface';
import { MappedOptions } from '../types/mapped-options-and-menus.interface';
import { useRouter } from 'next/router';
import { Helmet } from 'react-helmet';

type Props = {
  children: ReactNode;
  options: MappedOptions;
  menu: Menu;
  title: string;
  description: string;
  isInitiallyDark?: boolean;
};

const PageTemplate = ({
  menu,
  options,
  children,
  title,
  description,
  isInitiallyDark = true,
}: Props) => {
  const router = useRouter();
  return (
    <>
      <Helmet
        title={title}
        titleTemplate={`%s | ${options.siteName}`}
        defaultTitle={options.siteName}
      >
        <meta charSet="UTF-8" />
        <meta name="description" content={description || options.description} />
        <meta name="keywords" content={options.keywords} />
        <meta name="author" content={options.authors} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="30 days" />
        <link rel="canonical" href={options.baseUrl} />
        <meta property="og:title" content="" />
        <meta property="og:site_name" content={options.siteName} />
        <meta property="og:image" content={options.brandingImage} />
        <meta property="og:image:alt" content={options.siteName} />
        <meta
          property="og:url"
          content={`${options.baseUrl}${router.pathname}`}
        />
        <meta
          property="og:description"
          content={description || options.description}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={options.baseUrl} />
        <meta name="twitter:site" content={options.siteName} />
        <meta
          name="twitter:description"
          content={description || options.description}
        />
        <meta name="twitter:creator" content="@eabald" />
        <meta name="msapplication-TileColor" content="#FFF7E2" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#FFF7E2" />
      </Helmet>
      <Nav
        menu={menu}
        branding={options.brandingImage}
        isInitiallyDark={isInitiallyDark}
        baseUrl={options.baseUrl}
      />
      <main>{children}</main>
      <Footer options={options} />
    </>
  );
};

export default PageTemplate;
